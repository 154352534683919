import React from "react";
import { PageLayout } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import "../style/trivia.css";

export default class TriviaPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout className={"trivia-page"} hero={null}>
          <Grid stackable={true} columns={1}>
            <Grid.Column textAlign={"center"}>
              <Header as={"h1"}>Trivia</Header>
            </Grid.Column>
            <Grid.Column textAlign={"center"}>
              <p>
                Join us for Trivia every Tuesday night at 7pm, hosted by Trivia
                Mafia
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
